import Image from "next/legacy/image";
import imageUrlBuilder from "@sanity/image-url";
import client from "../../../client";
import { AutoScroller } from "@components/auto-scroller/auto-scroller";
import type { PartnerSection as SanityProps } from "@/types/generated/schema";
import Heading from "@components/heading";

const builder = imageUrlBuilder(client);

function PartnerSection(partnerSection: SanityProps): JSX.Element {
	return (
		<section className="relative mx-auto px-4 py-12 max-w-7xl overflow-hidden sm:px-6 md:py-20 lg:px-8 lg:py-24">
			<Heading className="pb-10">Onze partners</Heading>
			<AutoScroller>
				<ul className="flex space-x-8">
					{partnerSection.partners.map((partner, i) => (
						// @ts-ignore Partner is dereferenced

						<li key={i} className="flex flex-shrink-0">
							<Image
								src={builder
									// @ts-ignore Partner is dereferenced
									.image(partner.partnerImage)
									.width(120)
									.height(120)
									.auto("format")
									.quality(85)
									.url()}
								priority
								objectFit="cover"
								width="120"
								height="120"
								// @ts-ignore Partner is dereferenced
								alt={partner.partnerName}
							/>
						</li>
					))}
				</ul>
			</AutoScroller>
		</section>
	);
}

export default PartnerSection;
