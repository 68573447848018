import * as React from "react";
import Link from "next/link";
import clsx from "clsx";
import { ChevronRightIcon } from "icons/components";
import { type DereferenceRoutes, resolveLink } from "@/utils/link";
import IconPickerRenderer from "@components/icon-picker";
import SimpleBlockContent from "@components/SimpleBlockContent";
import type {
	Cta as RootCtaType,
	Hero as HeroType,
	SanityKeyed,
} from "@/types/generated/schema";

import sx from "./hero.module.css";

export type HeroProps = Omit<HeroType, "ctas"> & {
	backgroundImageUrl?: string;
	ctas?: Array<SanityKeyed<DereferenceRoutes<RootCtaType>>>;
};

function Hero({
	heading,
	tagline,
	ctas,
	backgroundImageUrl,
}: HeroProps): JSX.Element {
	return (
		<div className={clsx(sx.root, "min-h-hero lg:min-h-heroLarge")}>
			<div className={clsx(sx.imageOverlay, "absolute w-full h-full")}>
				<img
					src={backgroundImageUrl}
					className={clsx(sx.imageClip, "w-full h-full object-cover")}
					width={2000}
					height={1333}
					alt=""
				/>
			</div>
			{tagline && ctas ? (
				<>
					<div className="z-10 w-full px-4 pt-24 max-w-7xl min-h-heroLarge">
						<div className="grid grid-cols-8">
							<div className="col-start-1 col-end-9 lg:col-start-6">
								{tagline && (
									<h2 className="py-4 text-sm font-bold text-white uppercase font-rigBold lg:text-sm">
										{tagline}
									</h2>
								)}
								{ctas && (
									<div className="grid grid-cols-2 gap-6">
										{ctas.map((cta) => (
											<HeroCTA {...cta} key={cta._key} />
										))}
									</div>
								)}
							</div>
						</div>

						<h1
							className={clsx(
								sx.heading,
								"absolute -bottom-6 max-w-2xl text-8xl",
							)}
						>
							{heading}
						</h1>
					</div>
				</>
			) : (
				<div className="absolute flex flex-col justify-end w-full h-full -bottom-6 max-w-7xl">
					<h1 className={clsx(sx.heading)}>{heading}</h1>
				</div>
			)}
		</div>
	);
}

export default Hero;

export function HeroCTA({
	route,
	title,
	icon,
}: DereferenceRoutes<RootCtaType>): JSX.Element {
	return (
		<Link
			href={resolveLink(route)}
			className="p-4 text-sm font-bold text-black uppercase bg-white"
		>
			{icon.icon && <IconPickerRenderer icon={icon.icon} />}
			<div className="flex justify-between">
				{title && <SimpleBlockContent value={title} />}
				<ChevronRightIcon className="text-primary" width={28} height={28} />
			</div>
		</Link>
	);
}
