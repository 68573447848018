import * as React from "react";
import {
	motion,
	useSpring,
	useTransform,
	useViewportScroll,
} from "framer-motion";
import Link from "next/link";
import { BaronShortIcon, ChevronRightIcon } from "icons/components";
import { useElementLocationInfo, useDocumentHeight } from "@/utils/animation";
import { resolveLink } from "@/utils/link";
import IconPickerRenderer from "@components/icon-picker";
import SimpleBlockContent from "@components/SimpleBlockContent";
import type {
	SanityKeyed,
	PackageLink as RootPackageLink,
	PackagesSection as PackagesSectionTypes,
	Page,
	Service,
} from "@/types/generated/schema";

export type Props = Omit<PackagesSectionTypes, "packages"> & {
	packages?: Array<SanityKeyed<PackageLink>>;
};

type PackageLink = Omit<RootPackageLink, "route"> & {
	route?: Page | Service;
};

export const MovingIconWrapper = React.forwardRef<HTMLDivElement>((_, ref) => (
	<div ref={ref}>
		<BaronShortIcon
			className="w-32 h-32 lg:w-44 lg:h-44"
			width={176}
			height={176}
		/>
	</div>
));

MovingIconWrapper.displayName = "MovingIconWrapper";

const MovingIcon = motion(MovingIconWrapper);

function PackagesSection({ heading, packages }: Props): JSX.Element {
	const containerRef = React.useRef<HTMLDivElement>(null);
	const { offsetTopPixels: fromPixel } = useElementLocationInfo(containerRef);
	const { scrollY } = useViewportScroll();
	const documentHeight = useDocumentHeight();

	const transform = useTransform(
		scrollY,
		[fromPixel - documentHeight, fromPixel + documentHeight],
		[-125, 175],
	);
	const physics = { damping: 20, mass: 0.27, stiffness: 100 };
	const spring = useSpring(transform, physics);

	return (
		<div className="relative bg-black" ref={containerRef}>
			<motion.div className="absolute z-20 hidden pointer-events-none right-10 text-primary sm:flex">
				<MovingIcon
					style={{
						y: spring,
					}}
				/>
			</motion.div>
			<div className="max-w-3xl px-10 py-20 mx-auto">
				<h3 className="font-bold text-center uppercase text-primary">
					{heading}
				</h3>
				<div className="grid grid-cols-1 gap-6 py-10 md:grid-cols-3 lg:gap-20">
					{packages.map((item, i) => {
						const url = new URLSearchParams();
						if (item.block_id?.subject)
							url.set("scrollTo", item.block_id.subject);

						const link = `${resolveLink(item.route)}${
							item.block_id?.subject ? `#${item.block_id.subject}` : ""
						}`;

						return (
							<Package
								key={i}
								icons={item.icons}
								title={item.title}
								href={link}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default PackagesSection;

type PackageProps = Omit<RootPackageLink, "_type" | "route"> & {
	href: string;
};

function Package({ title, href, icons }: PackageProps) {
	return (
		<Link
			href={href}
			className="p-4 text-sm font-bold text-black uppercase bg-white"
		>
			<div className="flex">
				{icons.flatMap((item, index) => {
					if (!item.icon) return [];
					const isLastLink = index === icons.length - 1;

					return (
						<div key={item._key} className="flex text-black">
							<IconPickerRenderer icon={item.icon} aria-hidden="true" />
							{icons.length !== 0 && !isLastLink && (
								<span className="mx-2 font-bold" aria-hidden="true">
									+
								</span>
							)}
						</div>
					);
				})}
			</div>
			<div className="flex justify-between">
				{title && <SimpleBlockContent value={title} />}
				<ChevronRightIcon className="text-primary" width={28} height={28} />
			</div>
		</Link>
	);
}
