export { default as hero } from "./hero/hero";
export { default as textSection } from "./text-section";
export { default as reviewSection } from "./review-section";
export { default as partnerSection } from "./partner-section";
export { default as textWithUSPSection } from "./text-with-usps";
export { default as ctaSection } from "./call-to-action";
export { default as locationMap } from "./location-map";
export { default as quoteSection } from "./quote/quote";
export { default as contactForm } from "./contact-form";
export { default as latestPropositionsSection } from "./latestPropositions";
export { default as packagesSection } from "./packagesSection";
export { default as accordionSection } from "./accordionSection/index";
export { default as productPackages } from "./product-packages";
