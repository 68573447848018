import Link from "next/link";
import sx from "./proposition.module.css";
import TooltipButton from "@components/tooltip-button";
import {
	ObjectSpaceIcon,
	CalendarIcon,
	NewConstructionIcon,
	BedIcon,
} from "icons/components";
import { Status, statusOptions } from "@/routes/properties";
import clsx from "clsx";
import type { OfferJsonld } from "property-api/openapi";
import { getSrcSet } from "@/utils/media";

export const Proposition: React.FC<OfferJsonld> = (property) => {
	let slug = `/aanbod/${property.slug}`;
	if (property.itemType === "project")
		slug = `/aanbod/nieuwbouw/${property.slug}`;
	if (property.itemType === "bogObject")
		slug = `/aanbod/zakelijk/${property.slug}`;
	return (
		<div className="relative flex flex-col bg-lightGray">
			{!!property.image && property.image?.["1x"] && (
				<img
					className={clsx(
						{
							"opacity-75 grayscale filter":
								property.status !== Status.AVAILABLE &&
								property.status !== Status.PROSPECT,
						},
						"aspect-[400/266] object-cover",
					)}
					srcSet={getSrcSet(property.image)}
					src={property.image["1x"]}
					loading="lazy"
					width={400}
					height={266}
					alt=""
				/>
			)}
			{!!property.status &&
				property.status === Status.AVAILABLE &&
				property.itemType === "project" && (
					<div className="absolute left-0 p-4 font-bold text-black bg-primary top-6">
						{property.readableStatus}
					</div>
				)}
			{!!property.status && property.status !== Status.AVAILABLE && (
				<div className="absolute left-0 p-4 font-bold text-black bg-primary top-6">
					{property.readableStatus ?? statusOptions[property.status]}
				</div>
			)}
			<div className="flex flex-col p-6 space-y-2">
				<div className="text-sm font-bold text-black uppercase">
					{slug && (
						<Link href={slug} className={sx.link}>
							{/* @ts-ignore */}
							{property.title ?? ""}
						</Link>
					)}
				</div>
				{!!property.price && (
					<div
						className={clsx("text-primaryDark font-bold", {
							"line-through": property.status !== Status.AVAILABLE,
						})}
					>
						{property.price}
					</div>
				)}
			</div>
			<div className="flex flex-row flex-wrap gap-4 px-4 pb-4 mt-auto">
				{!!property.plotSurface &&
					Number(property.plotSurface) > 0 &&
					property.plotSurface !== undefined &&
					property.plotSurface !== null && (
						<TooltipButton
							icon={<ObjectSpaceIcon className="w-6 h-6" />}
							buttonContent={
								<p>
									{property.plotSurface}{" "}
									<span className="ordinal">
										m<sub className="-top-1">2</sub>
									</span>
								</p>
							}
							tooltipContent="Oppervlakte"
						/>
					)}
				{!!property.bedrooms && Number(property.bedrooms) > 0 && (
					<TooltipButton
						icon={<BedIcon className="w-6 h-6" />}
						buttonContent={<p>{property.bedrooms}</p>}
						tooltipContent="Slaapkamers"
					/>
				)}
				{!!property.buildYear &&
					Number(property.buildYear) > 0 &&
					property.itemType !== "project" && (
						<TooltipButton
							icon={<CalendarIcon className="w-6 h-6" />}
							buttonContent={<p>{property.buildYear}</p>}
							tooltipContent="Bouwjaar"
						/>
					)}
				{property.itemType === "project" && (
					<TooltipButton
						icon={<NewConstructionIcon className="w-6 h-6" />}
						buttonContent={<p>Nieuwbouwproject</p>}
						tooltipContent="Nieuwbouw"
					/>
				)}
			</div>
		</div>
	);
};
